<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <!-- filter begin-->
      <asom-card title="Discrepancy Report Search">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
          <asom-form-field
            label="Date Created"
            required
            :state="inputStates('filters.dateRange')"
          >
            <asom-input-date-range
              v-model="filters.dateRange"
              datepicker-placement="bottom"
              :state="inputStates('filters.dateRange')"
            />
          </asom-form-field>
        </div>
        <template #footer>
          <asom-button
            text="Reset Filters"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button text="Apply" @click="getPageData(true)" />
        </template>
      </asom-card>
      <!-- filter end-->
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'referenceNo',
          'transactionNo',
          'reportedBy',
          'status',
          'date',
          'time',
          'discrepancyType',
        ]"
        :data="reports"
        :sortableColumns="[
          'referenceNo',
          'transactionNo',
          'reportedBy',
          'status',
          'date',
          'time',
          'discrepancyType',
        ]"
        :searchableDateColumns="['dateTime']"
        :searchableTimeColumns="['dateTime']"
        :filterable="true"
        :pagination="true"
      >
        <template v-slot:header_assignedOfficer>Assigned To</template>
        <template v-slot:referenceNo="scopedSlots">
          <asom-link @click="navigateToView(scopedSlots.rowData)">
            {{ scopedSlots.data }}
          </asom-link>
        </template>
        <template v-slot:transactionNo="scopedSlots">
          <span v-if="scopedSlots.rowData.discrepancyTypeEnum !== 0">
            {{ scopedSlots.data }}
          </span>
          <span v-else>Others</span>
        </template>
        <template v-slot:status="scopedSlots">
          <span>{{ addSpacing(scopedSlots.data) }}</span>
        </template>
        <template v-slot:date="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.rowData.dateTime) }}</span>
        </template>
        <template v-slot:time="scopedSlots">
          <span>{{
            displayUtcTime(scopedSlots.rowData.dateTime)
          }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <discrepancy-reports-slide-menu
      v-model="showRightMenu"
      :selected-transaction="selectedTransaction"
      @assign="getPageData"
      @close="getPageData"
      @approve="getPageData"
    />
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { getMyDiscrepancyReports } from "@/services/cashManagement.service";
import DiscrepancyReportsSlideMenu from "./DiscrepancyReportsSlideMenu";
import {
  discrepancyReportStatus,
} from "../../../../constants/APIEnums/cashManagementEnums";
import {toMoment} from "@/helpers/dateTimeHelpers";
import inputStates from "@/mixins/inputStates";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "DiscrepancyReportsListNonAdmin",
  mixins: [inputStates],
  components: {
    DiscrepancyReportsSlideMenu,
  },
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        }
      },
      error: null,
      reports: [],
      isLoading: false,
      showRightMenu: false,
      selectedTransaction: null,
    };
  },
  computed: {
    ...mapGetters({
      userStationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      lineOptions: "smrtResource/lineOptions",
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    discrepancyStatus() {
      return [
        {
          value: discrepancyReportStatus.IN_REVIEW.VALUE,
          label: discrepancyReportStatus.IN_REVIEW.NAME,
        },
        {
          value: discrepancyReportStatus.APPROVED.VALUE,
          label: discrepancyReportStatus.APPROVED.NAME,
        },
        {
          value: discrepancyReportStatus.CLOSED.VALUE,
          label: discrepancyReportStatus.CLOSED.NAME,
        },
      ];
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    }
  },
  mounted() {
    const searchVal = getStorage("discrepancy-reports");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    
    this.$nextTick(() => {
      this.getPageData();
    })
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    addSpacing(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
    resetFilters() {
      this.filters.dateRange = null;
    },
    async getPageData(status) {
      if(status) setStorage("discrepancy-reports", this.filters);
      this.error = null;
      this.isLoading = true;
      const resp = await getMyDiscrepancyReports({
        stationId: this.userStationId,
        ...this.queryParams
      });
      if (resp.success) {
        this.reports = get(resp.payload, "dicrepancyReports", []);
        this.transactions = get(resp.payload, "activityLogs", []);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    navigateToView(transaction) {
      if (transaction) {
        this.selectedTransaction = transaction;
        this.showRightMenu = true;
      }
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    }
  },
};
</script>

<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <asom-card title="Filter">
        <asom-grid :sm="1" :md="2">
          <asom-form-field label="Date" required>
            <asom-input-date-range v-model="filters.dateRange" />
          </asom-form-field>
          <asom-form-field label="Line" required>
            <asom-input-select v-model="filters.line" :options="lineOptions" />
          </asom-form-field>
          <asom-form-field label="Stations">
            <asom-multi-select
              :objectModelValue="false"
              v-model="filters.stations"
              :options="stationOptions"
            />
          </asom-form-field>
          <asom-form-field label="Assigned To">
            <asom-multi-select
              :objectModelValue="true"
              @search-change="queryOfficerList"
              :loading="isLoadingOfficers"
              v-model="filters.assignedTo"
              :options="officerOptions"
              placeholder="Select officer(s)"
            />
          </asom-form-field>
          <asom-form-field label="Discrepancy Type">
            <asom-multi-select
              :objectModelValue="false"
              v-model="filters.discrepancyType"
              :options="discrepancyTypes"
              placeholder="Select Discrepancy Types"
          /></asom-form-field>
          <asom-form-field label="Discrepancy Status">
            <asom-multi-select
              :objectModelValue="false"
              v-model="filters.discrepancyStatus"
              :options="discrepancyStatus"
              placeholder="Select Discrepancy Status(es)"
          /></asom-form-field>
        </asom-grid>
        <template #footer>
          <asom-button
            text="Reset Filters"
            :disabled="isLoading"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button
            text="Apply"
            :disabled="isLoading"
            :loading="isLoading"
            @click="getPageData"
          />
        </template>
      </asom-card>
      <div v-if="!isLoading" class="space-y-4">
        <asom-card title="Discrepancy Reports List">
          <asom-client-table
            v-if="!isLoading"
            :columns="[
              'referenceNo',
              'transactionNo',
              'status',
              'line',
              'station',
              'date',
              'time',
              'discrepancyType',
              'reportedBy',
              'assignedOfficer',
            ]"
            :data="reports"
            :sortableColumns="[
              'referenceNo',
              'transactionNo',
              'status',
              'line',
              'station',
              'date',
              'discrepancyType',
            ]"
            :searchableDateColumns="['dateTime']"
            :searchableTimeColumns="['dateTime']"
            :filterable="true"
            :pagination="true"
          >
            <template v-slot:header_assignedOfficer>Assigned To</template>
            <template v-slot:referenceNo="scopedSlots">
              <asom-link @click="navigateToView(scopedSlots.rowData)">
                {{ scopedSlots.data }}
              </asom-link>
            </template>
            <template v-slot:transactionNo="scopedSlots">
              <span v-if="scopedSlots.rowData.discrepancyTypeEnum !== 0">
                {{ scopedSlots.data }}
              </span>
              <span v-else>Others</span>
            </template>
            <template v-slot:status="scopedSlots">
              <span>{{ addSpacing(scopedSlots.data) }}</span>
            </template>
            <template v-slot:date="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.rowData.dateTime) }}</span>
            </template>
            <template v-slot:time="scopedSlots">
              <span>{{
                displayUtcTime(scopedSlots.rowData.dateTime)
              }}</span>
            </template>
          </asom-client-table>
          <div v-else class="text-center">
            <asom-icon icon="spinner" class="animate-spin" />
          </div>
        </asom-card>
        <activity-log-list :data="transactions" title="Activity Log" />
      </div>
    </div>
    <discrepancy-reports-slide-menu
      v-model="showRightMenu"
      :selected-transaction="selectedTransaction"
      @assign="getPageData"
      @reject="getPageData"
      @close="getPageData"
      @approve="getPageData"
    />
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { getDiscrepancyReports } from "@/services/cashManagement.service";
import { listUsers } from "@/services/user.service";
import DiscrepancyReportsSlideMenu from "./DiscrepancyReportsSlideMenu";
import ActivityLogList from "../../_ActivityLogList.vue";
import {
  discrepancyNature,
  discrepancyReportStatus,
} from "../../../../constants/APIEnums/cashManagementEnums";
import { parseDateTimeUTC } from "@/helpers/dateTimeHelpers";

export default {
  name: "DiscrepancyReportsListAdmin",
  components: {
    DiscrepancyReportsSlideMenu,
    ActivityLogList,
  },
  data() {
    return {
      filters: {
        dateRange: {
          start: moment()
            .add(-1, "months")
            .toDate(),
          end: moment().add(1, 'days').toDate(),
        },
        assignedTo: [],
        discrepancyType: [],
        discrepancyStatus: [],
        line: this.$store.getters["auth/userLineOption"],
        stations: [],
      },
      officerOptions: [],
      error: null,
      reports: [],
      transactions: [],
      isLoading: false,
      showRightMenu: false,
      selectedTransaction: null,
      isLoadingOfficers: false,
    };
  },
  computed: {
    ...mapGetters({
      userStationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      lineOptions: "smrtResource/lineOptions",
    }),
    queryParams() {
      let params = {};
      if (this.filters.dateRange && this.filters.dateRange.start && this.filters.dateRange.end) {
        params["startDate"] = parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["endDate"] = parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    },
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    discrepancyStatus() {
      return [
        {
          value: discrepancyReportStatus.IN_REVIEW.VALUE,
          label: discrepancyReportStatus.IN_REVIEW.NAME,
        },
        {
          value: discrepancyReportStatus.APPROVED.VALUE,
          label: discrepancyReportStatus.APPROVED.NAME,
        },
        {
          value: discrepancyReportStatus.CLOSED.VALUE,
          label: discrepancyReportStatus.CLOSED.NAME,
        },
      ];
    },
    discrepancyTypes() {
      return [
        {
          value: discrepancyNature.CASH_BAG.VALUE,
          label: discrepancyNature.CASH_BAG.NAME,
        },
        {
          value: discrepancyNature.PRE_ENCODED_TICKET.VALUE,
          label: discrepancyNature.PRE_ENCODED_TICKET.NAME,
        },
        {
          value: discrepancyNature.CASH_FLOAT.VALUE,
          label: discrepancyNature.CASH_FLOAT.NAME,
        },
        {
          value: discrepancyNature.PSM_MANUAL_RECORD.VALUE,
          label: discrepancyNature.PSM_MANUAL_RECORD.NAME,
        },
        {
          value: discrepancyNature.CASH_BOX.VALUE,
          label: discrepancyNature.CASH_BOX.NAME,
        },
        {
          value: discrepancyNature.COIN_FLOAT.VALUE,
          label: discrepancyNature.COIN_FLOAT.NAME,
        },
        {
          value: discrepancyNature.SCCF.VALUE,
          label: discrepancyNature.SCCF.NAME,
        },
        {
          value: discrepancyNature.STATION_TRANSFER.VALUE,
          label: discrepancyNature.STATION_TRANSFER.NAME,
        },
        {
          value: discrepancyNature.CASH_DECLARATION_AFC.VALUE,
          label: discrepancyNature.CASH_DECLARATION_AFC.NAME,
        },
        {
          value: discrepancyNature.CASH_DECLARATION_PSC.VALUE,
          label: discrepancyNature.CASH_DECLARATION_PSC.NAME,
        },
        {
          value: discrepancyNature.OTHERS.VALUE,
          label: discrepancyNature.OTHERS.NAME,
        },
      ];
    },
  },
  mounted() {
    this.getPageData();
  },
  watch: {
    'filters.line': function(){
      this.resetFilters();
    }
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    addSpacing(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
    resetFilters() {
      this.filters.dateRange.start = moment()
        .add(-1, "months")
        .toDate();
      this.filters.dateRange.end = moment()
        .add(1, 'days')
        .toDate();
      this.filters.stations = [];
      this.filters.assignedTo = [];
      this.filters.discrepancyType = [];
      this.filters.discrepancyStatus = [];
    },
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = this.filters.assignedTo;
        return
      }
      const result = await listUsers({
        skip: 0,
        take: 100,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
        this.officerOptions.push(...this.filters.assignedTo);
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
        this.$scrollTop();
      }
    },
    async getPageData() {
      this.error = null;
      this.isLoading = true;
      const resp = await getDiscrepancyReports({
        // startDate: parseDateTimeToUtc(this.filters.dateRange.start),
        // endDate: parseDateTimeToUtc(this.filters.dateRange.end),
        assignTo: this.filters.assignedTo.map(option => option.value),
        discrepancyNatures: this.filters.discrepancyType,
        discrepancyStatuses: this.filters.discrepancyStatus,
        stationIds: this.filters.stations,
        lineId: this.filters.line.value,
        ...this.queryParams
      });
      if (resp.success) {
        this.reports = get(resp.payload, "dicrepancyReports", []);
        let _activityLogs = get(resp.payload, "activityLogs", []);
        const fields = ['actionTaken', 'involvedParties', 'description'];
        _activityLogs.forEach( log => {
            let result = [];
          var obj = get(log, "fieldChanges", {});
          if(obj){
            fields.forEach( field => {
              if((obj[field] || obj[field + 'Updated']) && obj[field] !== obj[field + 'Updated']){
                let temp =
                { fieldName: field,
                  values: {
                    oldValue: obj[field],
                    newValue: obj[field + 'Updated']
                  }
                }
                result.push(temp);
              }
            })
          }
          log.fieldChanges = result;
        })
        this.transactions = _activityLogs;

      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    navigateToView(transaction) {
      if (transaction) {
        this.selectedTransaction = transaction;
        this.showRightMenu = true;
      }
    },
  },
};
</script>

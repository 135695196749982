<template>
  <admin-main v-if="isAdminRole" />
  <non-admin-main v-else />
</template>
<script>
import { mapGetters } from 'vuex';
import AdminMain from './_AdminMain.vue';
import NonAdminMain from './_NonAdminMain.vue';

export default {
  components: {
    AdminMain,
    NonAdminMain,
  },
  computed: {
    ...mapGetters({
      'isAdminRole': 'auth/canViewCMDiscrepancyReport'
    })
  }
}
</script>